import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { TitleSection } from "../../shared/components/financialWarehouseDocumentRightPanel/titleSection/TitleSection";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { TradingDocumentsSection } from "./components/tradingDocumentsSection/TradingDocumentsSection";
import { LineItemSection } from "../../shared/components/financialWarehouseDocumentRightPanel/lineItemSection/LineItemSection";
import { whEntriesActions } from "api/wh-entries/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: reception, error, isLoading } = whEntriesActions.useFinancialWarehouseReception(
    Number(panelId),
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!reception) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={reception.id}>
        <PanelHeader reception={reception} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection signature={reception.signature} />
            <GeneralInfoSection reception={reception} />
            <LineItemSection documentType="RECEPTION" />
            <TradingDocumentsSection reception={reception} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
