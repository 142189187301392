import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useSelector } from "hooks";
import { useParams } from "react-router";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../shared/FinancialPackages.module.css";
import { FinancialWarehouseCurrentState } from "../financialWarehouseState/components/financialWarehouseCurrentState/FinancialWarehouseCurrentState";
import { SingleFinancialWarehouseStateSummary } from "./components/singleFinancialWarehouseStateSummary/SingleFinancialWarehouseStateSummary";
import { FinancialWarehouseReceptionsSummary } from "../shared/components/financialWarehouseReceptionsSummary/FinancialWarehouseReceptionsSummary";
import { FinancialWarehouseReturnsSummary } from "../shared/components/financialWarehouseReturnsSummary/FinancialWarehouseReturnsSummary";
import { FinancialWarehouseReleasesSummary } from "../shared/components/financialWarehouseReleasesSummary/FinancialWarehouseReleasesSummary";
import { FinancialWarehouseExpendituresSummary } from "../shared/components/financialWarehouseExpendituresSummary/FinancialWarehouseExpendituresSummary";
import { FinancialWarehouseStateDetails } from "../financialWarehouseState/components/financialWarehouseStateDetails/FinancialWarehouseStateDetails";
import { useState } from "react";
import { WarehouseDetailedStateChart } from "../financialWarehouseState/components/WarehouseDetailedStateChart";
import { useFilters } from "hooks/useFilters";
import { FinancialWarehouseStateSummaryFilters } from "../financialWarehouseState/components/financialWarehouseStateSummary/FinancialWarehouseStateSummary";
import { WarehouseStateChartContainer } from "../financialWarehouseState/components/WarehouseStateChart";
import { queryString } from "utilities";

export const SingleFinancialWarehouseState = () => {
  const { id } = useParams<{ tab: FinancialWarehouseStateTab; id: string }>();
  const [tab] = useState<FinancialWarehouseStateTab>(FinancialWarehouseStateTab.MONTHLY);
  const warehouses = useSelector(store => store.partials.warehouses);
  const { filters, setFilter } = useFilters<FinancialWarehouseStateSummaryFilters>({
    page: 1,
    pageSize: 6,
    warehouseId: id,
  });

  const warehouse = warehouses.find(warehouse => warehouse.id === Number(id));
  assertIsDefined(warehouse);

  return (
    <PageWrapper>
      <PageHeader
        bigTitle="Finansowy stan magazynu"
        searchBar={false}
        viewLabel="SINGLE_FINANCIAL_WAREHOUSE_STATE"
      />
      <div className="d-flex flex-column overflow-auto">
        <div className="py-2 px-3">
          <div className={styles.financialWarehouseStatePage}>
            <div className="pt-3 pb-2 px-3 d-flex align-items-center gap-4">
              <Typography fontSize="26" fontWeight="600">
                Wartość magazynu: {warehouse.name || warehouse.code}
              </Typography>
              {/* TODO: waiting for backend */}
              {/* <div className="d-flex align-items-baseline gap-1">
                <Typography
                  className="text-uppercase"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="700"
                >
                  Widok w podziale na:
                </Typography>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    tab !== FinancialWarehouseStateTab.MONTHLY &&
                    setTab(FinancialWarehouseStateTab.MONTHLY)
                  }
                >
                  <Tag
                    label="Miesiące"
                    type={tab === FinancialWarehouseStateTab.MONTHLY ? "filled" : "outlined"}
                    variant={
                      tab === FinancialWarehouseStateTab.MONTHLY ? "deepPurple50" : "quaternary"
                    }
                  />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    tab !== FinancialWarehouseStateTab.QUARTERLY &&
                    setTab(FinancialWarehouseStateTab.QUARTERLY)
                  }
                >
                  <Tag
                    label="Kwartały"
                    type={tab === FinancialWarehouseStateTab.QUARTERLY ? "filled" : "outlined"}
                    variant={
                      tab === FinancialWarehouseStateTab.QUARTERLY ? "deepPurple50" : "quaternary"
                    }
                  />
                </div>
              </div> */}
            </div>
            <div className="d-flex flex-column gap-4">
              <div className="d-flex gap-3">
                <div className="d-flex flex-1 flex-column w-50">
                  <FinancialWarehouseCurrentState warehouse={id} tab={tab} />
                  <SingleFinancialWarehouseStateSummary
                    key={id}
                    filters={filters}
                    setFilter={setFilter}
                    tab={tab}
                    warehouse={id}
                  />
                </div>
                <div className="d-flex flex-1 w-50">
                  <WarehouseStateChartContainer key={id} tab={tab} filters={filters} />
                </div>
              </div>
              <div className="d-flex flex-1 gap-3">
                <div className="d-flex flex-1 w-50">
                  <FinancialWarehouseStateDetails key={id} tab={tab} warehouse={id} />
                </div>

                <div className="d-flex flex-1 w-50">
                  <WarehouseDetailedStateChart
                    search={queryString.stringify({ warehouseId: id })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex py-2 px-3 gap-2">
          <div className="d-flex flex-1 overflow-hidden">
            <FinancialWarehouseReceptionsSummary tab={tab} warehouse={id} />
          </div>
          <div className="d-flex flex-1 overflow-hidden">
            <FinancialWarehouseReturnsSummary tab={tab} warehouse={id} />
          </div>
        </div>
        <div className="d-flex py-2 px-3 gap-2">
          <div className="d-flex flex-1 overflow-hidden">
            <FinancialWarehouseReleasesSummary tab={tab} warehouse={id} />
          </div>
          <div className="d-flex flex-1 overflow-hidden">
            <FinancialWarehouseExpendituresSummary tab={tab} warehouse={id} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
