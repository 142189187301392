import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { financialWarehouseDocumentSummaryColumnFactory } from "../../utils/financialWarehouseDocumentSummaryColumnFactory";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";

export const useFinancialWarehouseExpendituresSummaryColumns = (
  tab: FinancialWarehouseStateTab,
) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    const columns = financialWarehouseDocumentSummaryColumnFactory(columnHelper, "expenditures");
    return [columns.period(tab), columns.value({ header: "wartość rozchodów [PLN]", size: 150 })];
  });
};
