export enum FinancialWarehouseStateTab {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
}

export enum FinancialWarehouseStateMonthStatus {
  CALCULATING = "CALCULATING",
  CLOSED = "CLOSED",
  FROZEN = "FROZEN",
  OPENED = "OPENED",
}
