import { financialPackagesFileFactory } from "api/wms/financial-packages/fileFactory";
import { FinancialWarehouseReceptionDetails } from "api/wh-entries/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";
import { DocumentClosure } from "pages/financialPackages/shared/components/financialWarehouseDocumentRightPanel/documentClosure/DocumentClosure";
import { queryString } from "utilities";

interface Props {
  reception: FinancialWarehouseReceptionDetails;
}

export const PanelHeader = ({ reception }: Props) => {
  const { close } = useDrawer("financialWarehouseReceptions");
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      {isTriggerElementHidden ? (
        <SlidingHeaderWrapper className="gap-2">
          <DocumentClosure id={reception.id} status={reception.status} />
          <Typography fontSize="16" fontWeight="700">
            {reception.signature}
          </Typography>
        </SlidingHeaderWrapper>
      ) : (
        <DocumentClosure id={reception.id} status={reception.status} />
      )}
      <div className="d-flex align-items-center justify-content-end gap-1">
        <FileDownloadHandler
          factoryFn={() =>
            financialPackagesFileFactory.whEntryPackagesLabel(
              queryString.stringify({
                whEntryId: reception.id,
              }),
              reception.signature,
            )
          }
          type="pdf"
        >
          {({ download, isLoading }) => (
            <Tooltip title="Pobierz etykietę">
              <IconButton
                icon={MdiQrCode}
                isLoading={isLoading}
                onClick={download}
                variant="transparent"
              />
            </Tooltip>
          )}
        </FileDownloadHandler>
        <Tooltip title="Pobierz PDF">
          <IconButton disabled icon={MdiDownloadPdf} variant="transparent" />
        </Tooltip>
        <span className="divider line-divider" />
        <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
      </div>
    </RightPanelHeader>
  );
};
