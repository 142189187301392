import { Pagination, UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { whEntriesKeys } from "./keys";
import {
  FinancialWarehouseDocumentsSummary,
  FinancialWarehouseExpenditureDetails,
  FinancialWarehouseExpenditureListItem,
  FinancialWarehouseReceptionDetails,
  FinancialWarehouseReceptionItem,
  FinancialWarehouseReleaseDetails,
  FinancialWarehouseReleaseListItem,
  FinancialWarehouseReturnDetails,
  FinancialWarehouseReturnListItem,
  LineItem,
} from "./models";
import { queryFetch } from "apiConnectors/queryFetch";
import { FinancialWarehouseDocumentStatus } from "./enums";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const getFinancialWarehouseReceptions = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseReceptionItem>> => ({
  key: whEntriesKeys.receptions.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/receptions" + search,
    }),
});

const getFinancialWarehouseReception = (
  id: FinancialWarehouseReceptionDetails["id"],
): ApiFetcher<FinancialWarehouseReceptionDetails> => ({
  key: whEntriesKeys.receptions.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/receptions/" + id,
    }),
});

const patchFinancialWarehouseReception = (
  data: Assign<
    Partial<FinancialWarehouseReceptionDetails>,
    { id: FinancialWarehouseReceptionDetails["id"] }
  >,
) =>
  queryFetch<FinancialWarehouseReceptionDetails>({
    method: "PATCH",
    url: "/wh-entries/receptions/" + data.id,
    data: parsePatchData(data),
  });

const getFinancialWarehouseReleases = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseReleaseListItem>> => ({
  key: whEntriesKeys.releases.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/releases" + search,
    }),
});

const getFinancialWarehouseRelease = (
  id: FinancialWarehouseReleaseDetails["id"],
): ApiFetcher<FinancialWarehouseReleaseDetails> => ({
  key: whEntriesKeys.releases.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/releases/" + id,
    }),
});

const getFinancialWarehouseReturns = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseReturnListItem>> => ({
  key: whEntriesKeys.returns.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/returns" + search,
    }),
});

const getFinancialWarehouseReturn = (
  id: FinancialWarehouseReturnDetails["id"],
): ApiFetcher<FinancialWarehouseReturnDetails> => ({
  key: whEntriesKeys.returns.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/returns/" + id,
    }),
});

const patchFinancialWarehouseReturn = (
  data: Assign<
    Partial<FinancialWarehouseReturnDetails>,
    { id: FinancialWarehouseReturnDetails["id"] }
  >,
) =>
  queryFetch<FinancialWarehouseReturnDetails>({
    method: "PATCH",
    url: "/wh-entries/returns/" + data.id,
    data: parsePatchData(data),
  });

const getFinancialWarehouseExpenditures = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseExpenditureListItem>> => ({
  key: whEntriesKeys.expenditures.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/expenditures" + search,
    }),
});

const getFinancialWarehouseExpenditure = (
  id: FinancialWarehouseExpenditureDetails["id"],
): ApiFetcher<FinancialWarehouseExpenditureDetails> => ({
  key: whEntriesKeys.expenditures.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/expenditures/" + id,
    }),
});

const patchFinancialWarehouseExpenditure = (
  data: Assign<
    Partial<FinancialWarehouseExpenditureDetails>,
    { id: FinancialWarehouseExpenditureDetails["id"] }
  >,
) =>
  queryFetch<FinancialWarehouseExpenditureDetails>({
    method: "PATCH",
    url: "/wh-entries/expenditures/" + data.id,
    data: parsePatchData(data),
  });

const getFinancialWarehouseDocumentsSummary = (
  search: string = "",
): ApiFetcher<Pagination<FinancialWarehouseDocumentsSummary>> => ({
  key: whEntriesKeys.warehouseDocumentsSummary.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/summary" + search,
    }),
});

const postConfirmEntry = (id: UUID | number) =>
  queryFetch<{ message: string; status: FinancialWarehouseDocumentStatus }>({
    method: "POST",
    url: `/wh-entries/items/${id}/confirm`,
  });

const getLineItems = (search: string = ""): ApiFetcher<Pagination<LineItem>> => ({
  key: whEntriesKeys.lineItems(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/wh-entries/line-items" + search,
    }),
});

export const whEntriesApi = {
  getFinancialWarehouseDocumentsSummary,
  getFinancialWarehouseExpenditures,
  getFinancialWarehouseExpenditure,
  getFinancialWarehouseReceptions,
  getFinancialWarehouseReception,
  getFinancialWarehouseReleases,
  getFinancialWarehouseRelease,
  getFinancialWarehouseReturns,
  getFinancialWarehouseReturn,
  patchFinancialWarehouseExpenditure,
  patchFinancialWarehouseReception,
  patchFinancialWarehouseReturn,
  postConfirmEntry,
  getLineItems,
};
