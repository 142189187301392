import { InventoryCheckTab } from "api/wh-inventory-check/enums";

const inventoryChecksTabDict: Record<InventoryCheckTab, string> = {
  all: "Wszystkie",
  "in-progress": "W trakcie",
  closed: "Zamknięte",
};

export const inventoryChecksConstants = {
  inventoryChecksTabDict,
};
