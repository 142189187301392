import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { TitleSection } from "../../shared/components/financialWarehouseDocumentRightPanel/titleSection/TitleSection";
import { GeneralInfoSection } from "./components/generalInfoSection/GeneralInfoSection";
import { PanelHeader } from "./components/panelHeader/PanelHeader";
import { TradingDocumentSection } from "pages/financialPackages/shared/components/financialWarehouseDocumentRightPanel/tradingDocumentSection/TradingDocumentSection";
import { LineItemSection } from "pages/financialPackages/shared/components/financialWarehouseDocumentRightPanel/lineItemSection/LineItemSection";
import { whEntriesActions } from "api/wh-entries/actions";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: release, error, isLoading } = whEntriesActions.useFinancialWarehouseRelease(
    panelId,
    {
      enabled: Boolean(panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!release) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={release.id}>
        <PanelHeader release={release} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <TitleSection signature={release.signature} />
            <GeneralInfoSection release={release} />
            <LineItemSection documentType="RELEASE" />
            <TradingDocumentSection title="Faktury" tradingDocuments={release.tradingDocuments} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
