import { TabProps } from "api/types";
import { dictToList, queryString } from "utilities";
import { financialPackagesConstants } from "../constants/financialPackagesConstants";
import { FinancialWarehouseDocumentSearchProps } from "api/wh-entries/models";
import { FinancialWarehouseDocumentTab } from "api/wh-entries/enums";

const getFinancialWarehouseStateDocumentSearch = ({
  query,
  tab,
}: FinancialWarehouseDocumentSearchProps) => {
  const tabsQuery = {
    status:
      tab === "opened"
        ? FinancialWarehouseDocumentTab.OPENED
        : tab === "closed"
        ? FinancialWarehouseDocumentTab.CLOSED
        : "",
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};

const getFinancialWarehouseStateDocumentTabs = (): TabProps => {
  return dictToList(financialPackagesConstants.financialWarehouseDocumentsTabDict).map(
    ({ key, value }) => ({
      label: value as string,
      name: key,
    }),
  );
};

export const financialPackagesUtils = {
  getFinancialWarehouseStateDocumentSearch,
  getFinancialWarehouseStateDocumentTabs,
};
