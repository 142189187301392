import {
  FinancialWarehouseDocumentTab,
  FinancialWarehouseExpenditureReason,
  FinancialWarehouseReturnReason,
} from "api/wh-entries/enums";
import {
  FinancialWarehouseExpenditureListItem,
  FinancialWarehouseReturnListItem,
} from "api/wh-entries/models";
import { FinancialWarehouseStateMonthStatus } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { MdiPoint } from "components/miloDesignSystem/atoms/icons/mdiPoint/MdiPoint";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const financialWarehouseDocumentsTabDict: Record<FinancialWarehouseDocumentTab, string> = {
  all: "Wszystkie",
  closed: "Zamknięte",
  opened: "Otwarte",
};

const getWarehouseSummaryStatusTag = (
  status: FinancialWarehouseStateSummaryPerPeriod["status"],
) => {
  switch (status) {
    case FinancialWarehouseStateMonthStatus.FROZEN:
      return (
        <Tag.WithCustomColor backgroundColor="cyan100" label="Przeliczony" textColor="cyan600" />
      );
    case FinancialWarehouseStateMonthStatus.CALCULATING:
      return (
        <Tag.WithCustomColor
          backgroundColor="orange100"
          label="Trwa przeliczanie"
          startIcon={MdiPoint}
          textColor="orange600"
        />
      );
    case FinancialWarehouseStateMonthStatus.CLOSED:
      return (
        <Tag.WithCustomColor backgroundColor="green75" label="Zamknięty" textColor="green600" />
      );
    case FinancialWarehouseStateMonthStatus.OPENED:
      return (
        <Tag.WithCustomColor backgroundColor="indigo75" label="Otwarty" textColor="indigo600" />
      );
    default:
      const exhaustiveCheck: never = status;
      console.error(`Unhandled summary status: ${exhaustiveCheck}`);
      return <EmptyValue fontSize="14" />;
  }
};

const getExpenditureReason = (
  reason: FinancialWarehouseExpenditureListItem["expenditureReason"],
) => {
  switch (reason) {
    case FinancialWarehouseExpenditureReason.LOSS:
      return "strata";
    case FinancialWarehouseExpenditureReason.OWN_PURCHASES:
      return "potrzeby własne";
    default:
      const exhaustiveCheck: never = reason;
      console.error(`Unhandled return reason: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};

const getReturnReason = (reason: FinancialWarehouseReturnListItem["returnReason"]) => {
  switch (reason) {
    case FinancialWarehouseReturnReason.CANCELATION:
      return "reklamacja";
    case FinancialWarehouseReturnReason.COMPLAINT:
      return "rezygnacja";
    case FinancialWarehouseReturnReason.NO_COLLECTION:
      return "brak odbioru";
    case FinancialWarehouseReturnReason.OTHER:
      return "inny";
    default:
      const exhaustiveCheck: never = reason;
      console.error(`Unhandled return reason: ${exhaustiveCheck}`);
      return EMPTY_VALUE;
  }
};

export const financialPackagesConstants = {
  financialWarehouseDocumentsTabDict,
  getExpenditureReason,
  getWarehouseSummaryStatusTag,
  getReturnReason,
};
