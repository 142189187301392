import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useQuery, useSelector, useStateModal } from "hooks";
import { financialWarehouseStateUtils } from "pages/financialPackages/financialWarehouseState/utils/financialWarehouseStateUtils";
import { useSingleFinancialWarehouseStateSummaryColumns } from "./useSingleFinancialWarehouseStateSummaryColumns";
import { FinancialWarehouseStateSummaryPerPeriod } from "api/wms/financial-packages/financial-warehouse-state/models";
import { PeriodCalculationModal } from "../periodCalculation/PeriodCalculationModal";
import { assertIsDefined } from "utilities/assertIsDefined";
import { EmptySummarySection } from "pages/financialPackages/shared/components/emptySummarySection/EmptySummarySection";
import { FinancialWarehouseStateSummaryFilters } from "pages/financialPackages/financialWarehouseState/components/financialWarehouseStateSummary/FinancialWarehouseStateSummary";
import warehouseValueIcon from "assets/images/warehouseValue.svg";

interface Props {
  filters: FinancialWarehouseStateSummaryFilters;
  setFilter: (key: keyof FinancialWarehouseStateSummaryFilters, value: number) => void;
  tab: FinancialWarehouseStateTab;
  warehouse: string;
}

export const SingleFinancialWarehouseStateSummary = ({
  filters,
  setFilter,
  tab,
  warehouse,
}: Props) => {
  const { query } = useQuery();
  const warehouses = useSelector(store => store.partials.warehouses);
  const currentWarehouse = warehouses.find(
    searchedWarehouse => searchedWarehouse.id === Number(warehouse),
  );
  assertIsDefined(currentWarehouse);
  const search = financialWarehouseStateUtils.getSearch({
    query,
    tab,
    filters: {
      ...filters,
      warehouseId: warehouse,
    },
  });
  const {
    data: summaryPeriods,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financialWarehouseStateActions.useFinancialWarehouseStateGeneral(search);
  const periodCalculationModal = useStateModal<FinancialWarehouseStateSummaryPerPeriod>();
  const columns = useSingleFinancialWarehouseStateSummaryColumns(tab, periodCalculationModal.open);
  const tableProps = useTableFeatureConnector({
    rows: summaryPeriods,
  });

  return (
    <>
      <div className="d-flex flex-column">
        <Table<FinancialWarehouseStateSummaryPerPeriod>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          pagination={pagination}
          onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
          uiSchema={{
            cell: { height: "36" },
            header: { backgroundColor: "neutralWhite100" },
          }}
          {...tableProps}
          overrides={() => ({
            ...tableProps?.overrides?.(),
            noResultComponent: (
              <EmptySummarySection
                icon={warehouseValueIcon}
                title="Nieznany stan wartości magazynu w ostatnich miesiącach"
              />
            ),
          })}
        />
      </div>
      {periodCalculationModal.isOpen && periodCalculationModal.state && (
        <PeriodCalculationModal
          close={periodCalculationModal.close}
          state={{ ...periodCalculationModal.state, warehouse: currentWarehouse }}
        />
      )}
    </>
  );
};
