import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";

const tabsDict: Record<FinancialWarehouseStateTab, string> = {
  monthly: "Miesięcznie",
  quarterly: "Kwartalnie",
};

export const financialWarehouseStateConstants = {
  tabsDict,
};
