import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const whEntriesKeys = {
  receptions: {
    list: createPaginatedKey("financialWarehouseReceptions"),
    details: (id: number) => ["financialWarehouseReception", id],
  },
  releases: {
    list: createPaginatedKey("financialWarehouseReleases"),
    details: (id: UUID) => ["financialWarehouseRelease", id],
  },
  returns: {
    list: createPaginatedKey("financialWarehouseReturns"),
    details: (id: number) => ["financialWarehouseReturn", id],
  },
  expenditures: {
    list: createPaginatedKey("financialWarehouseExpenditures"),
    details: (id: number) => ["financialWarehouseExpenditure", id],
  },
  warehouseDocumentsSummary: {
    list: createPaginatedKey("financialWarehouseDocumentsSummary"),
  },
  lineItems: createPaginatedKey("financialWarehouseLineItems"),
};
