import { TabProps } from "api/types";
import { dateFns, dictToList, queryString } from "utilities";
import { financialWarehouseStateConstants } from "../constants/financialWarehouseStateConstants";
import { SearchProps } from "api/wms/financial-packages/financial-warehouse-state/models";
import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { pl } from "date-fns/locale";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { endOfQuarter, isSameMonth, isWithinInterval, startOfQuarter } from "date-fns";
import { assertIsDefined } from "utilities/assertIsDefined";

const getTabs = (): TabProps =>
  dictToList(financialWarehouseStateConstants.tabsDict).map(({ key, value }) => ({
    label: value,
    name: key,
  }));

const getSearch = <T,>({ query, tab, filters }: SearchProps<T>): string => {
  const tabsQuery = {
    groupBy:
      tab === "monthly" ? FinancialWarehouseStateTab.MONTHLY : FinancialWarehouseStateTab.QUARTERLY,
  };
  return queryString.stringify({
    ...query,
    ...tabsQuery,
    ...filters,
  });
};

const formatDateAbbreviation = (date: string): string => {
  const monthPattern = /^\d{4}-(\d{2})$/;
  const quarterPattern = /^\d{4}-(I|II|III|IV)$/;

  if (monthPattern.test(date)) {
    return dateFns.format(dateFns.parse(date, "yyyy-MM", new Date()), "MMM. yyyy", {
      locale: pl,
    });
  } else if (quarterPattern.test(date)) {
    const quarterMatch = date.match(quarterPattern);
    if (quarterMatch) {
      const [, quarter] = quarterMatch;
      const year = date.slice(0, 4);
      return `${quarter} kw. ${year}`;
    }
  }
  return EMPTY_VALUE;
};

/**
 * @param date "2024-12" | "2024-III"
 */
const isCurrentPeriod = (date: string): boolean => {
  const monthPattern = /^\d{4}-(\d{2})$/;
  const quarterPattern = /^\d{4}-(I|II|III|IV)$/;

  if (monthPattern.test(date)) {
    const parsedDate = dateFns.parse(date, "yyyy-MM", new Date());
    return isSameMonth(parsedDate, new Date());
  } else if (quarterPattern.test(date)) {
    const quarterMatch = date.match(quarterPattern);
    if (quarterMatch) {
      const [, quarter] = quarterMatch;
      const year = parseInt(date.slice(0, 4), 10);

      const quarterStartMonth = { I: 0, II: 3, III: 6, IV: 9 }[quarter];
      assertIsDefined(quarterStartMonth);
      const start = startOfQuarter(new Date(year, quarterStartMonth));
      const end = endOfQuarter(new Date(year, quarterStartMonth));

      return isWithinInterval(new Date(), { start, end });
    }
  }
  return false;
};

const getMonthNameInPolish = (date: string): string => {
  const parsedDate = dateFns.parse(date, "yyyy-MM", new Date());
  return dateFns.format(parsedDate, "LLLL", { locale: pl });
};

export const financialWarehouseStateUtils = {
  formatDateAbbreviation,
  getMonthNameInPolish,
  getSearch,
  getTabs,
  isCurrentPeriod,
};
