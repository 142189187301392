import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { financialWarehouseStateUtils } from "../../utils/financialWarehouseStateUtils";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { MdiInfo } from "components/miloDesignSystem/atoms/icons/MdiInfo";
import { formatSubUnitsToDecimal } from "utilities";

export const useFinancialWarehouseStateDetailsColumns = (
  tab: FinancialWarehouseStateTab,
  pageSize: number,
) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: tab === FinancialWarehouseStateTab.MONTHLY ? "miesiąc" : "kwartał",
        size: 78,
        cell: info => {
          const index = info.row.index;
          const periodDetails = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="12" fontWeight="700">
                {financialWarehouseStateUtils.formatDateAbbreviation(periodDetails.periodId)}
              </Typography>
              {index === pageSize - 1 && periodDetails.previousTotal && (
                <Tooltip
                  title={`${financialWarehouseStateUtils.getMonthNameInPolish(
                    periodDetails.previousTotal.periodId,
                  )}: ${Number(
                    formatSubUnitsToDecimal(periodDetails.previousTotal.totalValue),
                  ).toLocaleString("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} PLN`}
                >
                  <div className="d-flex align-items-center pb-1">
                    <MdiInfo color="info400" size="16" />
                  </div>
                </Tooltip>
              )}
            </div>
          );
        },
      }),
      columnHelper.amount(
        row =>
          row.receptions
            ? formatSubUnitsToDecimal(row.receptions.totalValue + row.receptions.estimatedValue)
            : 0,
        {
          header: "przyjęcia",
          size: 130,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            noSeparator: true,
          },
        },
      ),
      columnHelper.amount(
        row =>
          row.releases
            ? formatSubUnitsToDecimal(row.releases.totalValue + row.releases.estimatedValue)
            : 0,
        {
          header: "wydania",
          size: 130,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            noSeparator: true,
          },
        },
      ),
      columnHelper.amount(
        row =>
          row.returns
            ? formatSubUnitsToDecimal(row.returns.totalValue + row.returns.estimatedValue)
            : 0,
        {
          header: "zwroty",
          size: 130,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            noSeparator: true,
          },
        },
      ),
      columnHelper.amount(
        row =>
          row.expenditures
            ? formatSubUnitsToDecimal(row.expenditures.totalValue + row.expenditures.estimatedValue)
            : 0,
        {
          header: "rozchody",
          size: 130,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            noSeparator: true,
          },
        },
      ),
      columnHelper.amount(
        row => formatSubUnitsToDecimal(row.total.totalValue + row.total.estimatedValue),
        {
          header: "stan",
          size: 130,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            noSeparator: true,
          },
        },
      ),
    ];
  });
};
