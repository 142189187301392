export enum FinancialWarehouseDocumentStatus {
  CLOSED = "CLOSED",
  OPENED = "OPENED",
}

export enum FinancialWarehouseDocumentTab {
  ALL = "all",
  CLOSED = "closed",
  OPENED = "opened",
}

export enum FinancialWarehouseDocumentCategory {
  EXPENDITURE = "EXPENDITURE",
  RECEPTION = "RECEPTION",
  RELEASE = "RELEASE",
  RETURN = "RETURN",
}

export enum FinancialWarehouseReturnReason {
  CANCELATION = "CANCELATION",
  COMPLAINT = "COMPLAINT",
  NO_COLLECTION = "NO_COLLECTION",
  OTHER = "OTHER",
}

export enum FinancialWarehouseExpenditureReason {
  LOSS = "LOSS",
  OWN_PURCHASES = "OWN_PURCHASES",
}

export enum LineItemKind {
  PRODUCT = "PRODUCT",
  PACKAGE = "PACKAGE",
}
