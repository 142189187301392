import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQuery } from "hooks";
import { useFilters } from "hooks/useFilters";
import { financialWarehouseStateUtils } from "../../utils/financialWarehouseStateUtils";
import { financialWarehouseStateActions } from "api/wms/financial-packages/financial-warehouse-state/actions";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useFinancialWarehouseStateDetailsColumns } from "./useFinancialWarehouseStateDetailsColumns";
import { useMemo } from "react";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { EmptySummarySection } from "pages/financialPackages/shared/components/emptySummarySection/EmptySummarySection";
import balanceIcon from "assets/images/balanceAlt.svg";

interface Props {
  tab: FinancialWarehouseStateTab;
  warehouse?: string;
}

interface Filters {
  page: number;
  pageSize: number;
  warehouseId: string;
}

export const FinancialWarehouseStateDetails = ({ tab, warehouse }: Props) => {
  const { filters, setFilter } = useFilters<Filters>({
    page: 1,
    pageSize: 6,
    warehouseId: warehouse ?? "",
  });
  const { query } = useQuery();
  const search = financialWarehouseStateUtils.getSearch({ query, tab, filters });
  const {
    data: periodsDetails,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = financialWarehouseStateActions.useFinancialWarehouseStateDetails(search);
  const normalizedPeriodDetails: NormalizedFinancialWarehouseStateDetails[] = useMemo(() => {
    return periodsDetails.map(period => ({ ...period, id: period.periodId }));
  }, [periodsDetails]);
  const columns = useFinancialWarehouseStateDetailsColumns(tab, filters.pageSize);
  const tableProps = useTableFeatureConnector({
    rows: normalizedPeriodDetails,
  });

  return (
    <div className="d-flex flex-column overflow-hidden">
      <div className="pt-3 pb-2 px-3 d-flex align-items-center gap-2">
        <Typography fontSize="20" fontWeight="400">
          Przychody i rozchody
        </Typography>
      </div>
      <Table<NormalizedFinancialWarehouseStateDetails>
        columns={columns}
        isLoading={isLoading || isPreviousData}
        pagination={pagination}
        onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
        error={error}
        uiSchema={{
          cell: { height: "36" },
          header: { backgroundColor: "neutralWhite100" },
        }}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          noResultComponent: (
            <EmptySummarySection
              icon={balanceIcon}
              title="Brak danych na liście Przychodów i Rozchodów"
            />
          ),
        })}
      />
    </div>
  );
};
