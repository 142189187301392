import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { financialWarehouseDocumentSummaryColumnFactory } from "../../utils/financialWarehouseDocumentSummaryColumnFactory";
import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";

export const useSingleFinancialWarehouseReturnsSummaryColumns = (
  tab: FinancialWarehouseStateTab,
) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    const columns = financialWarehouseDocumentSummaryColumnFactory(columnHelper, "returns");
    return [
      columns.period(tab),
      columns.status(),
      columns.value({ header: "wartość zwrotów [PLN]", size: 200 }),
      columnHelper.stretchContent,
      columns.closedAt(),
    ];
  });
};
