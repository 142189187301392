import { ISODateTime } from "api/types";
import {
  FinancialWarehouseExpenditureDetails,
  FinancialWarehouseReceptionDetails,
  FinancialWarehouseReleaseDetails,
  FinancialWarehouseReturnDetails,
} from "api/wh-entries/models";
import { InfoLabel } from "components/common/infoLabel";
import { Avatar } from "components/miloDesignSystem/atoms/avatar/Avatar";
import { Link } from "components/miloDesignSystem/atoms/link";
import { IconRenderer } from "components/miloDesignSystem/atoms/shared/IconRenderer";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { warehouseConstants } from "constants/warehouse";
import { dateUtils, formatSubUnitsToDecimal } from "utilities";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const generalInfoSectionFactory = (
  financialWarehouseDocument:
    | FinancialWarehouseReceptionDetails
    | FinancialWarehouseReleaseDetails
    | FinancialWarehouseReturnDetails
    | FinancialWarehouseExpenditureDetails,
) => {
  return {
    date: ({ date, title }: { date: ISODateTime | null; title: string }) => (
      <InfoLabel title={title}>
        <Typography fontSize="14" fontWeight="400">
          {date ? dateUtils.formatDateToDisplay(date) : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    ),
    warehouse: () => (
      <InfoLabel title="magazyn">
        {financialWarehouseDocument.warehouse ? (
          <div className="d-flex align-items-center gap-1">
            <IconRenderer
              color="neutralBlack100"
              icon={warehouseConstants.getWarehouseIcons(financialWarehouseDocument.warehouse.icon)}
              size={18}
            />
            <Typography fontSize="14" fontWeight="600" noWrap>
              {financialWarehouseDocument.warehouse.code ||
                financialWarehouseDocument.warehouse.name}
            </Typography>
          </div>
        ) : (
          <EmptyValue fontSize="14" />
        )}
      </InfoLabel>
    ),
    closedBy: () => (
      <InfoLabel title="kto zamknął">
        {financialWarehouseDocument.closedBy ? (
          <div className="d-flex align-items-center gap-1">
            <Avatar size="sm" user={financialWarehouseDocument.closedBy} />
            <Typography fontSize="14" fontWeight="500">
              {financialWarehouseDocument.closedBy.firstName}{" "}
              {financialWarehouseDocument.closedBy.lastName}
            </Typography>
          </div>
        ) : (
          <EmptyValue fontSize="14" />
        )}
      </InfoLabel>
    ),
    order: ({ order }: { order: FinancialWarehouseReleaseDetails["order"] }) => (
      <InfoLabel title="zamówienie">
        {order ? (
          <Link fontSize="14" fontWeight="500" to={`/orders/active/list/all?panelId=${order.id}`}>
            {order.signature}
          </Link>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
    ),
    route: ({ route }: { route: FinancialWarehouseReleaseDetails["source"] }) => (
      <InfoLabel title="trasa">
        {route ? (
          <Link fontSize="14" fontWeight="500" to={`/routes/list/active?panelId=${route.id}`}>
            {route.signature}
          </Link>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
    ),
    totalAmount: ({
      title,
      totalAmount,
    }: {
      totalAmount: FinancialWarehouseReleaseDetails["totalAmount"];
      title: string;
    }) => (
      <InfoLabel title={title}>
        {totalAmount ? (
          <AmountDisplay
            emptyValueRenderer={value => value === null && EMPTY_VALUE}
            amount={formatSubUnitsToDecimal(totalAmount)}
            currency="PLN"
            decimalTypographyProps={{
              fontSize: "12",
              fontWeight: "600",
            }}
            integerTypographyProps={{
              fontSize: "14",
              fontWeight: "800",
            }}
          />
        ) : (
          <EmptyValue fontSize="14" />
        )}
      </InfoLabel>
    ),
    /**
     * @deprecated
     */
    receiver: ({ receiver }: { receiver: any }) => (
      <InfoLabel title="odbiorca">
        <Typography fontSize="14" fontWeight="500">
          {receiver?.name || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    ),
  };
};
