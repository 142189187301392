import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { RightPanelSection } from "components/utils/drawer";
import { useTradingDocumentsColumns } from "./useTradingDocumentsColumns";
import { EmptySection } from "components/miloDesignSystem/molecules/emptySection/EmptySection";
import { FinancialWarehouseReceptionDetails } from "api/wh-entries/models";

interface Props {
  reception: FinancialWarehouseReceptionDetails;
}

export const TradingDocumentsSection = ({ reception }: Props) => {
  const tableProps = useTableFeatureConnector({
    rows: reception.tradingDocuments,
  });
  const columns = useTradingDocumentsColumns();

  return (
    <RightPanelSection className="mt-3" title="Faktury">
      <Table<FinancialWarehouseReceptionDetails["tradingDocuments"][number]>
        columns={columns}
        error={null}
        isLoading={false}
        uiSchema={{
          cell: { height: "22" },
          header: { backgroundColor: "neutralWhite100" },
        }}
        {...tableProps}
        overrides={() => ({
          ...tableProps?.overrides?.(),
          hideHeader: !reception.tradingDocuments.length,
          noResultComponent: <EmptySection label="Brak faktur" />,
        })}
      />
    </RightPanelSection>
  );
};
