import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { warehouseDocumentsStatesColumnFactory } from "../shared/utils/warehouseDocumentsStatesColumnFactory";
import { FinancialWarehouseReceptionItem } from "api/wh-entries/models";

export const useFinancialWarehouseReceptionsColumns = () => {
  // @ts-ignore
  return useCreateTableColumns<FinancialWarehouseReceptionItem>(({ columnHelper }) => {
    const columns = warehouseDocumentsStatesColumnFactory(columnHelper);
    return [
      columns.signature(),
      columns.status(),
      columnHelper.accessor(row => row.numberOfPackages, {
        id: "packages",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            liczba paczek
          </Typography>
        ),
        size: 100,
        cell: info => {
          const packages = info.getValue();
          return (
            <div className="d-flex align-items-baseline gap-1 justify-content-end w-100">
              <div className="d-flex">
                <Typography color="success500" fontSize="14" fontWeight="700">
                  {packages.received}
                </Typography>
                <Typography fontSize="14" fontWeight="700">
                  /{packages.total}
                </Typography>
              </div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columns.created(),
      columns.closed(),
      columnHelper.link({
        header: "nr zlecenia",
        size: 140,
        textRenderer: row => row.externalManufacturingOrder?.signature,
        to: row =>
          `/external-manufacturing/orders/list/all?panelId=${row.externalManufacturingOrder?.id}`,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "dostawca",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columns.totalAmount(),
      columns.warehouse(),
      columnHelper.accessor(row => row.tradingDocuments, {
        header: "faktury zakupowe",
        size: 130,
        cell: info => {
          const tradingDocuments = info.getValue();
          if (!Boolean(tradingDocuments.length)) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="600">
                {tradingDocuments[0].signature}
              </Typography>
              {tradingDocuments.length > 1 && (
                <Typography color="deepPurple400" fontSize="14" fontWeight="600">
                  +{tradingDocuments.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.externalNumber, {
        header: "nr obcy",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
        },
      }),
    ];
  });
};
