export enum InventoryCheckTab {
  ALL = "all",
  CLOSED = "closed",
  IN_PROGRESS = "in-progress",
}

export enum InventoryCheckType {
  FULL = "FULL",
  PARTIAL = "PARTIAL",
}
