import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiFinance } from "components/miloDesignSystem/atoms/icons/MdiFinance";
import { FinancialWarehouseStateRouting } from "routes/finances/financialPackages/FinancialWarehouseStateRouting";

export const financialWarehouseState: ModuleLink = {
  url: "financial-warehouse-state",
  label: "Zbiorczo",
  icon: MdiFinance,
  routing: FinancialWarehouseStateRouting,
};
