import { Route } from "components/utils";
import { FinancialWarehouseReceptions } from "pages/financialPackages/financialWarehouseReceptions/FinancialWarehouseReceptions";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import { NotFound } from "../../../pages/notFound/NotFound";

export const FinancialWarehouseReceptionsRouting = ({
  match,
}: RouteComponentProps): JSX.Element => {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        component={FinancialWarehouseReceptions}
        exact
        path={`${match.path}/list/:tab`}
        title="Milo - przyjęcia"
      />
      <Route component={NotFound} exact path="*" title="Milo" />
    </Switch>
  );
};
