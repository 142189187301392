import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { whEntriesApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import { whEntriesKeys } from "./keys";
import { MG } from "utilities/mockGenerator";
import { OrderTypeChoices } from "api/orders/enums";
import {
  FinancialWarehouseDocumentStatus,
  FinancialWarehouseExpenditureReason,
  FinancialWarehouseReturnReason,
} from "./enums";
import {
  InvoiceType,
  TradingDocumentPaymentStatus,
  TradingDocumentType,
} from "api/trading-documents/models";
import { WarehouseIconKind } from "api/wms/models";

const useFinancialWarehouseReceptions = createPaginatedApiQuery(
  whEntriesApi.getFinancialWarehouseReceptions,
);
const useFinancialWarehouseReception = createApiQuery(whEntriesApi.getFinancialWarehouseReception);

const useFinancialWarehouseExpenditures = createPaginatedApiQuery(
  whEntriesApi.getFinancialWarehouseExpenditures,
  () => ({
    id: MG.number({ min: 1, max: 10000 }),
    signature: MG.signature({ collectionType: "EXPENDITURES" }),
    status: FinancialWarehouseDocumentStatus.CLOSED,
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
    totalAmount: MG.number({ min: 10000, max: 1000000 }),
    closed: MG.isoDateTime(),
    created: MG.isoDateTime(),
    numberOfPackages: MG.number({ min: 1, max: 50 }),
    expenditureReason: FinancialWarehouseExpenditureReason.LOSS,
    createdBy: MG.userWithInitials(),
  }),
);
const useFinancialWarehouseExpenditure = createApiQuery(
  whEntriesApi.getFinancialWarehouseExpenditure,
  () => ({
    id: MG.number({ min: 1, max: 10000 }),
    signature: MG.signature({ collectionType: "EXPENDITURES" }),
    status: FinancialWarehouseDocumentStatus.CLOSED,
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
    totalAmount: MG.number({ min: 10000, max: 1000000 }),
    closed: MG.isoDateTime(),
    created: MG.isoDateTime(),
    numberOfPackages: MG.number({ min: 1, max: 50 }),
    expenditureReason: FinancialWarehouseExpenditureReason.LOSS,
    createdBy: MG.userWithInitials(),
    closedBy: MG.userWithInitials(),
  }),
);

const usePatchFinancialWarehouseExpenditure = () => {
  return useMutation(whEntriesApi.patchFinancialWarehouseExpenditure, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.expenditures.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.expenditures.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.expenditures.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(
        whEntriesKeys.expenditures.details(id),
        onMutationReturn.prevPanel,
        error,
      );
    },
  }));
};

const useFinancialWarehouseReturns = createPaginatedApiQuery(
  whEntriesApi.getFinancialWarehouseReturns,
  () => ({
    id: MG.number({ min: 1, max: 10000 }),
    signature: MG.signature({ collectionType: "RETURNS" }),
    status: FinancialWarehouseDocumentStatus.CLOSED,
    closed: MG.isoDateTime(),
    created: MG.isoDateTime(),
    receiver: {
      id: MG.uuid(),
      name: MG.companyName(),
    },
    returnReason: FinancialWarehouseReturnReason.CANCELATION,
    totalAmount: MG.number({ min: 10000, max: 1000000 }),
    tradingDocument: {
      id: MG.uuid(),
      invoiceType: "CORRECTION" as InvoiceType,
      signature: MG.signature({ collectionType: "CORRECTION" }),
      type: "INVOICE" as TradingDocumentType,
    },
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
    order: {
      id: MG.id(),
      type: OrderTypeChoices.STANDARD,
      signature: MG.signature({ collectionType: "ORDER" }),
    },
    route: MG.optionalField({
      id: MG.id(),
      signature: MG.signature({ collectionType: "ROUTE" }),
    }),
    shippingService: {
      id: MG.id(),
      name: MG.companyName(),
      provider: MG.companyName(),
    },
  }),
);
const useFinancialWarehouseReturn = createApiQuery(
  whEntriesApi.getFinancialWarehouseReturn,
  () => ({
    id: MG.number({ min: 1, max: 10000 }),
    signature: MG.signature({ collectionType: "RETURNS" }),
    status: FinancialWarehouseDocumentStatus.CLOSED,
    closed: MG.isoDateTime(),
    created: MG.isoDateTime(),
    receiver: {
      id: MG.uuid(),
      name: MG.companyName(),
    },
    returnReason: FinancialWarehouseReturnReason.CANCELATION,
    totalAmount: MG.number({ min: 10000, max: 1000000 }),
    warehouse: {
      code: MG.string({ length: 6 }),
      icon: "ROUND" as WarehouseIconKind,
      id: MG.id(),
      name: MG.companyName(),
    },
    order: {
      id: MG.id(),
      type: OrderTypeChoices.STANDARD,
      signature: MG.signature({ collectionType: "ORDER" }),
    },
    route: MG.optionalField({
      id: MG.id(),
      signature: MG.signature({ collectionType: "ROUTE" }),
    }),
    shippingService: {
      id: MG.id(),
      name: MG.companyName(),
      provider: MG.companyName(),
    },
    tradingDocuments: [
      {
        id: MG.uuid(),
        signature: MG.signature({ collectionType: "CORRECTION" }),
        currency: "PLN",
        amountSummary: {
          totalTax: MG.number({ min: 1, max: 10000 }),
          totalWithoutTax: MG.number({ min: 1, max: 10000 }),
          totalWithTax: MG.number({ min: 1, max: 10000 }),
          totalWithTaxInWords: "",
        },
        paymentDeadline: MG.isoDateTime(),
        paymentStatus: "PARTIALLY_PAID" as TradingDocumentPaymentStatus,
        invoiceType: "CORRECTION" as InvoiceType,
        type: "INVOICE" as TradingDocumentType,
      },
    ],
    closedBy: MG.userWithInitials(),
  }),
);

const usePatchFinancialWarehouseReturn = () => {
  return useMutation(whEntriesApi.patchFinancialWarehouseReturn, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.returns.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.returns.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.returns.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(whEntriesKeys.returns.details(id), onMutationReturn.prevPanel, error);
    },
  }));
};

const useFinancialWarehouseReleases = createPaginatedApiQuery(
  whEntriesApi.getFinancialWarehouseReleases,
);

const useFinancialWarehouseRelease = createApiQuery(whEntriesApi.getFinancialWarehouseRelease);

const useLineItems = createPaginatedApiQuery(whEntriesApi.getLineItems);

const useCloseDocument = () => {
  return useMutation(whEntriesApi.postConfirmEntry, ({ queryUtils, toastr }) => ({
    ...queryUtils.invalidateOnSuccessAndHandleError,
    onSuccess: () => {
      toastr.open({
        text: "Pomyślnie zamknięto dokument",
        title: "Udało się!",
        type: "success",
      });
      queryUtils.invalidateOnSuccessAndHandleError.onSuccess();
    },
  }));
};

const usePatchFinancialWarehouseReception = () => {
  return useMutation(whEntriesApi.patchFinancialWarehouseReception, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        whEntriesKeys.receptions.list(),
        id,
        toUpdate,
      );
      const prevPanel = queryUtils.handleMutate(whEntriesKeys.receptions.details(id), toUpdate);

      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollbackList(whEntriesKeys.receptions.list(), onMutationReturn.prevList, id);
      queryUtils.rollback(whEntriesKeys.receptions.details(id), onMutationReturn.prevPanel, error);
    },
  }));
};

export const whEntriesActions = {
  useCloseDocument,
  useFinancialWarehouseExpenditures,
  useFinancialWarehouseExpenditure,
  useFinancialWarehouseReceptions,
  useFinancialWarehouseReception,
  useFinancialWarehouseReleases,
  useFinancialWarehouseRelease,
  useFinancialWarehouseReturns,
  useFinancialWarehouseReturn,
  usePatchFinancialWarehouseExpenditure,
  usePatchFinancialWarehouseReception,
  usePatchFinancialWarehouseReturn,
  useLineItems,
};
