import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../../FinancialPackages.module.css";
import emptyListIcon from "assets/images/folder.svg";

interface Props {
  icon?: string;
  title: string;
}

export const EmptySummarySection = ({ icon, title }: Props) => {
  return (
    <div className="d-flex flex-column align-items-center pt-3 pb-4">
      <img alt="Brak pozycji" className={styles.emptyListIcon} src={icon ?? emptyListIcon} />
      <Typography fontSize="16" fontWeight="700" className="my-2">
        {title}
      </Typography>
    </div>
  );
};
