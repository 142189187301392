import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { financialWarehouseDocumentSummaryColumnFactory } from "../../utils/financialWarehouseDocumentSummaryColumnFactory";
import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";

export const useFinancialWarehouseReceptionsSummaryColumns = (tab: FinancialWarehouseStateTab) => {
  return useCreateTableColumns<NormalizedFinancialWarehouseStateDetails>(({ columnHelper }) => {
    const columns = financialWarehouseDocumentSummaryColumnFactory(columnHelper, "receptions");
    return [
      columns.period(tab),
      columns.value({ header: "wartość przyjęć [PLN]", size: 150 }),
      columnHelper.stretchContent,
      columns.estimationValue({ size: 150 }),
      columns.percentageEstimation(),
    ];
  });
};
