import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReceptions } from "components/miloDesignSystem/atoms/icons/MdiReceptions";
import { FinancialWarehouseReceptionsRouting } from "routes/finances/financialPackages/FinancialWarehouseReceptions";

export const financialWarehouseReceptions: ModuleLink = {
  url: "receptions",
  label: "Przyjęcia",
  icon: MdiReceptions,
  routing: FinancialWarehouseReceptionsRouting,
};
