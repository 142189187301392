import { CURRENCY_TYPE } from "CONSTANTS";
import { FinancialWarehouseReleaseDetails } from "api/wh-entries/models";
import { PaymentStatus } from "pages/tradingDocuments/shared/columnsFactory/columnFactory";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { tradingDocumentUtils } from "utilities/tradingDocuments";

export const useTradingDocumentsColumns = () => {
  return useCreateTableColumns<FinancialWarehouseReleaseDetails["tradingDocuments"][number]>(
    ({ columnHelper }) => {
      return [
        columnHelper.link({
          header: "numer",
          size: 292,
          to: row => tradingDocumentUtils.getTradingDocumentLink(row.id, row.type, row.invoiceType),
          textRenderer: row => row.signature,
          typographyProps: {
            fontSize: "12",
            fontWeight: "600",
          },
        }),
        columnHelper.amount(row => row.amountSummary.totalWithTax, {
          header: "kwota",
          size: 90,
          amountDisplayProps: {
            decimal: {
              fontSize: "10",
              fontWeight: "500",
            },
            noSeparator: true,
            currency: row => (row.original.currency as CURRENCY_TYPE) || undefined,
          },
        }),
        columnHelper.date(row => row.paymentDeadline, {
          header: "termin płatności",
          size: 100,
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.accessor(row => row, {
          header: "status",
          size: 130,
          cell: info => {
            const tradingDocument = info.getValue();
            return (
              <PaymentStatus
                paymentDeadline={tradingDocument.paymentDeadline}
                paymentStatus={tradingDocument.paymentStatus}
              />
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
