import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiReleases } from "components/miloDesignSystem/atoms/icons/MdiReleases";
import { FinancialWarehouseReleasesRouting } from "routes/finances/financialPackages/FinancialWarehouseReleasesRouting";

export const financialWarehouseReleases: ModuleLink = {
  url: "releases",
  label: "Wydania",
  icon: MdiReleases,
  routing: FinancialWarehouseReleasesRouting,
};
