import { useQuery } from "hooks";
import { useParams } from "react-router";
import { financialPackagesUtils } from "../shared/utils/financialPackagesUtils";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useFinancialWarehouseReleasesColumns } from "./useFinancialWarehouseReleasesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { PageHeader } from "components/common/pageHeader/PageHeader";
import { financialWarehouseReleases } from "components/common/moduleNavigation/moduleConfig/finances/routes/financialWarehouseReleases";
import { RightPanel } from "./rightPanel/RightPanel";
import { FinancialWarehouseDocumentTab } from "api/wh-entries/enums";
import { whEntriesActions } from "api/wh-entries/actions";
import { FinancialWarehouseReleaseListItem } from "api/wh-entries/models";

export const FinancialWarehouseReleases = () => {
  const { tab } = useParams<{ tab: FinancialWarehouseDocumentTab }>();
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = financialPackagesUtils.getFinancialWarehouseStateDocumentSearch({
    query,
    tab,
  });
  const {
    data: releases,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = whEntriesActions.useFinancialWarehouseReleases(search);
  const columns = useFinancialWarehouseReleasesColumns();
  const tableProps = useTableFeatureConnector({
    rows: releases,
    withDrawer: "financialWarehouseReleases",
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  return (
    <PageWrapper>
      <Header />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<FinancialWarehouseReleaseListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={{
            cell: { height: "36" },
            header: { backgroundColor: "grey25" },
          }}
          {...tableProps}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};

const Header = () => {
  return (
    <PageHeader
      searchInput={{
        label: "Szukaj...",
        tags: [],
      }}
      tabs={{
        list: financialPackagesUtils.getFinancialWarehouseStateDocumentTabs(),
        routesRoot: `finances/${financialWarehouseReleases.url}`,
        urlSpan: "list",
      }}
      viewLabel="FINANCIAL_WAREHOUSE_RELEASES"
    />
  );
};
