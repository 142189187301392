import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { warehouseDocumentsStatesColumnFactory } from "../shared/utils/warehouseDocumentsStatesColumnFactory";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { FinancialWarehouseReleaseListItem } from "api/wh-entries/models";

export const useFinancialWarehouseReleasesColumns = () => {
  // @ts-ignore
  return useCreateTableColumns<FinancialWarehouseReleaseListItem>(({ columnHelper }) => {
    const columns = warehouseDocumentsStatesColumnFactory(columnHelper);
    return [
      columns.signature(),
      columns.status(),
      columnHelper.accessor(row => row.numberOfPackages, {
        id: "packages",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            paczki
          </Typography>
        ),
        size: 100,
        cell: info => {
          const packages = info.getValue();
          return (
            <div className="d-flex align-items-baseline gap-1 justify-content-end w-100">
              <div className="d-flex">
                <Typography color="success500" fontSize="14" fontWeight="700">
                  {packages.received}
                </Typography>
                <Typography fontSize="14" fontWeight="700">
                  /{packages.total}
                </Typography>
              </div>
              <Typography color="neutralBlack48" fontSize="12" fontWeight="600">
                szt.
              </Typography>
            </div>
          );
        },
      }),
      columns.created(),
      columns.closed(),
      columnHelper.text(
        row => row.delivery?.companyName || `${row.delivery?.firstName} ${row.delivery?.lastName}`,
        {
          header: "odbiorca",
          size: 130,
        },
      ),
      columns.totalAmount(),
      columns.warehouse(),
      // columns.tradingDocument("powiązana faktura"),
      columnHelper.accessor(row => row.source, {
        header: "trasa/grupa",
        size: 130,
        cell: info => {
          const source = info.getValue();
          if (!source) return <EmptyValue fontSize="14" />;
          return (
            <Link
              fontSize="14"
              fontWeight="500"
              to={
                source.kind === "ROUTE"
                  ? `/routes/list/active?panelId=${source.id}`
                  : `/order-groups/list/orders?panelId=${source.id}`
              }
            >
              {source.signature}
            </Link>
          );
        },
      }),
      columnHelper.link({
        header: "zamówienie",
        size: 130,
        textRenderer: row => row.order?.signature,
        to: row =>
          row.order
            ? getOrderRedirectUrl({
                id: row.order.id,
                isCanceled: false,
                isSettled: false,
                signature: row.order.signature,
                type: row.order.type,
              })
            : "",
      }),
      // columns.shippingService(),
    ];
  });
};
