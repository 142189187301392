import { ColumnDef } from "@tanstack/table-core";
import { FinancialWarehouseStateTab } from "api/wms/financial-packages/financial-warehouse-state/enums";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { financialWarehouseStateUtils } from "pages/financialPackages/financialWarehouseState/utils/financialWarehouseStateUtils";
import {
  ColumnHelperArgs,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { financialPackagesConstants } from "../constants/financialPackagesConstants";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { NormalizedFinancialWarehouseStateDetails } from "api/wms/financial-packages/financial-warehouse-state/models";
import { formatSubUnitsToDecimal } from "utilities";

export const financialWarehouseDocumentSummaryColumnFactory = (
  columnHelper: ColumnHelperArgs<NormalizedFinancialWarehouseStateDetails>,
  documentCategory: "expenditures" | "receptions" | "releases" | "returns",
) => {
  return {
    period: (tab: FinancialWarehouseStateTab) =>
      columnHelper.accessor(row => row.periodId, {
        header: tab === FinancialWarehouseStateTab.MONTHLY ? "miesiąc" : "kwartał",
        size: 80,
        cell: info => {
          const period = info.getValue();
          return (
            <Typography fontSize="12" fontWeight="700">
              {financialWarehouseStateUtils.formatDateAbbreviation(period)}
            </Typography>
          );
        },
      }) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
    value: ({ header, size }: { header: string; size: number }) =>
      columnHelper.amount(
        row =>
          row[documentCategory]
            ? formatSubUnitsToDecimal(
                row[documentCategory]!.estimatedValue + row[documentCategory]!.totalValue,
              )
            : 0,
        {
          header,
          size,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "700",
            },
            decimal: {
              fontSize: "12",
              fontWeight: "600",
            },
            noSeparator: true,
          },
        },
      ) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
    percentageEstimation: () =>
      columnHelper.accessor(
        row =>
          row[documentCategory] ? row[documentCategory]!.estimatedValuePercentage.toFixed(2) : null,
        {
          id: "estimationPercentage",
          header: () => (
            <Typography
              className="text-center"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              % wyceny
            </Typography>
          ),
          size: 65,
          cell: info => {
            const percentageEstimation = info.getValue();
            if (!percentageEstimation) return <EmptyValue fontSize="14" />;
            return (
              <Typography className="w-100 text-right" fontSize="14" fontWeight="400">
                {percentageEstimation}%
              </Typography>
            );
          },
        },
      ) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
    status: () =>
      columnHelper.accessor(row => row, {
        header: "status",
        size: 100,
        cell: info => {
          const documentStates = info.getValue();
          if (financialWarehouseStateUtils.isCurrentPeriod(documentStates.periodId))
            return (
              <Tag.WithCustomColor
                backgroundColor="deepPurple75"
                label="Aktualny"
                textColor="deepPurple600"
              />
            );
          return documentStates[documentCategory] ? (
            financialPackagesConstants.getWarehouseSummaryStatusTag(
              documentStates[documentCategory]!.status,
            )
          ) : (
            <EmptyValue fontSize="14" />
          );
        },
      }) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
    estimationValue: ({ size }: { size: number }) =>
      columnHelper.amount(
        row =>
          row[documentCategory]
            ? formatSubUnitsToDecimal(row[documentCategory]!.estimatedValue)
            : 0,
        {
          header: "kwota wyceny",
          size,
          amountDisplayProps: {
            integer: {
              fontSize: "14",
              fontWeight: "500",
            },
            decimal: {
              fontSize: "12",
              fontWeight: "600",
            },
            noSeparator: true,
          },
        },
      ) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
    closedAt: () =>
      columnHelper.date(row => (row[documentCategory] ? row[documentCategory]!.closedAt : null), {
        header: "zamknięto",
        size: 90,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }) as ColumnDef<NormalizedFinancialWarehouseStateDetails>,
  };
};
